// storage.ts of [snippethub-web], at 210921
import { combineReducers, createStore } from "redux";
import { themingReducer } from "./theming";
import { composeWithDevTools } from "redux-devtools-extension";
import { TypedUseSelectorHook, useSelector } from "react-redux";

/* ======== CONFIGURE STORE ======== */

/* ==== REDUCERS ==== */

const reducers = combineReducers({
  theming: themingReducer,
});

/* ======== STORE ======== */
// export const store = configureStore(reducers, composeWithDevTools());
// FOR TS use configureStore
export const store = createStore(reducers, composeWithDevTools());
/* ======== tried to dynamically compose ======== */
// var store;
// const devEnv = process.env.NODE_ENV === "development";
// if (devEnv) {
//   import("redux-devtools-extension").then(({ composeWithDevTools }) => {
//     store = createStore(reducers, composeWithDevTools());
//   });
// } else {
//   store = createStore(reducers);
// }
// export { store };
// export (store as RootStoreState)  as "store";
/* ======== MIDDLEWARE ======== */
// NONE
/* ======== TYPING ======== */
export type RootStoreState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
const useAppSelector: TypedUseSelectorHook<RootStoreState> = useSelector;
export { useAppSelector as useSelector };
