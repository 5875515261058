// RedirectPage.tsx of [snippethub-web], at 210928
/* Here I do the backend things. */
/* Temporarily Only for GH OAuth */

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { GhOauthRedirectPage } from "./redirect/GhOauthRedirectPage";

export function RedirectPage() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} />
      <Route exact path={`${path}/github`} children={<GhOauthRedirectPage />} />
      <Route
        exact
        path={`${path}/github/:goal`}
        children={<GhOauthRedirectPage />}
      />
    </Switch>
  );
}
