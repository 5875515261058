// SignUpPage.tsx of [snippethub-web], at 210926

import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputLabel,
  Link,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { SimplePageWrap } from "UI/SimplePageWrap";
import { FormInfo } from "UI/types";
import { ValidatingField } from "UI/ValidatingField";
import { getCurrUser, login, signUp } from "util/aws-services";

export function SignUpPage() {
  let history = useHistory();

  const defaultFormInfo = {
    value: "",
    isValid: true,
  };
  const [username, setUsername] = useState<FormInfo>({ ...defaultFormInfo });
  const [email, setEmail] = useState<FormInfo>({ ...defaultFormInfo });
  const [password, setPassword] = useState<FormInfo>({ ...defaultFormInfo });
  const [isAgreed, setIsAgreed] = useState<boolean>(true); // repeated to improve useEffect
  const [isOpenSnackBar, setIsOpenSnackBar] = useState<boolean>(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const usernameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const userAgreementRef = useRef<HTMLInputElement>(null);
  const [isDisabledCreateBtn, setIsDisabledCreateBtn] = useState<boolean>(
    false
  );

  // disable create button
  useEffect(() => {
    if (
      !isAgreed ||
      ![username, email, password].map((fi) => fi.isValid).every(Boolean)
    ) {
      setIsDisabledCreateBtn(true);
    } else {
      setIsDisabledCreateBtn(false);
    }
  }, [username, email, password, isAgreed]);

  // helper functions
  const showSnackBar = useCallback((message: string) => {
    setIsOpenSnackBar(true);
    setSnackBarMsg(message);
  }, []);
  const hideSnackBar = useCallback(() => {
    setIsOpenSnackBar(false);
  }, []);
  const localFormCheck = useCallback(
    (username, email, password): boolean => {
      const checkList: [
        FormInfo,
        React.RefObject<HTMLInputElement>,
        string
      ][] = [
        [username, usernameRef, "username"],
        [email, emailRef, "email"],
        [password, passwordRef, "password"],
      ];
      for (const [info, ref, name] of checkList) {
        if (info.value === "") {
          ref.current?.focus();
          showSnackBar(`Please fill out the field: ${name}`);
          return false;
        }
      }
      if (!userAgreementRef.current?.checked) {
        userAgreementRef.current?.focus();
        showSnackBar(
          `Please agree End User License Agreement and Privacy Policy.`
        );
        return false;
      }
      return true;
    },
    [showSnackBar]
  );
  const AWSFormCheck = useCallback(
    async (username, email, password): Promise<boolean> => {
      if (!username.value || !email.value || !password.value) {
        //asserted in LOCAL CHECK for loop, repeat for TS
        return false;
      }
      const signUpResponse = await signUp({
        username: username.value,
        email: email.value,
        password: password.value,
      });
      if (signUpResponse.exit === "success") {
        await login({ username: username.value, password: password.value });
        const currUser = await getCurrUser();
        if (!currUser.username) {
          console.error("auto login failed");
          return false;
        } else {
          history.push("/");
          return true;
        }
      } else if (signUpResponse.exit === "error") {
        switch (signUpResponse.data.code) {
          case "UsernameExistsException":
            usernameRef.current?.focus();
            showSnackBar(
              `The username ${username.value} is already taken. Please try another`
            );
            return false;
          default:
            console.error({ signUpResponse });
            return false;
        }
      } else {
        console.error("unknown signUpResponse");
        return false;
      }
    },
    [history, showSnackBar]
  );
  const handleFormSubmit = useCallback(
    async (event, username, email, password) => {
      event.preventDefault();
      if (!localFormCheck(username, email, password)) {
        return false;
      }
      if (!(await AWSFormCheck(username, email, password))) {
        return false;
      }
    },
    [localFormCheck, AWSFormCheck]
  );

  return (
    <SimplePageWrap>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isOpenSnackBar}
        autoHideDuration={6000}
        onClose={hideSnackBar}
      >
        <Alert
          severity="error"
          action={
            <Button color="secondary" size="small" onClick={hideSnackBar}>
              CLOSE
            </Button>
          }
        >
          {snackBarMsg}
        </Alert>
      </Snackbar>
      <form
        onSubmit={(event) => {
          handleFormSubmit(event, username, email, password);
        }}
      >
        <Typography variant="h5">Sign up</Typography>
        <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
        <InputLabel>Username</InputLabel>
        <ValidatingField
          infoStateHook={[username, setUsername]}
          lengthControl={[3, Infinity]}
          validators={[
            [
              "Name may not contain non-url-safe chars",
              (s) => /^[.a-zA-Z0-9_-]*$/.test(s),
            ],
            ['Name may not start with "."', (s: string) => !s.startsWith(".")],
          ]}
          autocomplete="username"
          inputRef={usernameRef}
          variant="outlined"
          size="small"
        />
        <InputLabel>Email address</InputLabel>
        <ValidatingField
          infoStateHook={[email, setEmail]}
          autocomplete="email"
          inputRef={emailRef}
          variant="outlined"
          size="small"
          type="email"
        />
        <InputLabel>Password</InputLabel>
        <ValidatingField
          infoStateHook={[password, setPassword]}
          type="password"
          autocomplete="password"
          inputRef={passwordRef}
          lengthControl={[8, Infinity]}
          variant="outlined"
          size="small"
        />
        <span>
          <span style={{ fontWeight: 700 }}>Note:</span> Your email address will
          be added to the metadata of snippets that you publish, so it may be
          seen publicly.
        </span>
        <span>Your password should be at least 8 characters. </span>
        <Link>Learn more</Link>
        <Paper variant="outlined" style={{ padding: "10px" }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event, checked) => {
                  setIsAgreed(checked);
                }}
                inputRef={userAgreementRef}
              />
            }
            label={
              <div>
                Agree to the{" "}
                <Link
                  href="/policies/terms"
                  target="_blank"
                  title="SnippetHub, Inc. Terms and Licenses"
                >
                  End User License Agreement
                </Link>{" "}
                and the{" "}
                <Link
                  href="/policies/privacy"
                  target="_blank"
                  title="SnippetHub, Inc. Privacy Policy"
                >
                  Privacy Policy
                </Link>
                .
              </div>
            }
          />
        </Paper>
        <Button variant="outlined" type="submit" disabled={isDisabledCreateBtn}>
          Create an Account
        </Button>
      </form>
      <Link style={{ alignSelf: "center", fontWeight: "bold" }} href="/login">
        or, Login
      </Link>
    </SimplePageWrap>
  );
}
