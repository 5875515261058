import { Brightness4, Brightness7, BrightnessAuto } from "@mui/icons-material";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import { usePaletteDarkMode } from "util/hooks/usePaletteDarkMode";

export function Navbar() {
  const { paletteMode, togglePaletteDarkMode } = usePaletteDarkMode();
  function test() {
    const devEnv = process.env.NODE_ENV === "development";
    if (devEnv) {
      // import("secret/github").then(({ gh }) => {
      //   const reqIdUrl = `https://github.com/login/oauth/authorize?client_id=${gh.clientId}`;
      //   window.location.href = reqIdUrl;
      // });
      alert("test function disabled");
    }
    alert("You should not see this test button. Nothing happened.");
  }
  return (
    <div>
      <IconButton color="primary" onClick={togglePaletteDarkMode}>
        {paletteMode === "dark" ? (
          <Brightness4 />
        ) : paletteMode === "light" ? (
          <Brightness7 />
        ) : (
          <BrightnessAuto />
        )}
      </IconButton>
      <ButtonGroup
        variant="contained"
        color="primary"
        aria-label="primary button group"
      >
        {process.env.NODE_ENV === "development" && (
          <Button onClick={test}>Test</Button>
        )}
        <Button>Products</Button>
        <Button>Pricing</Button>
        <Button>Documentation</Button>
        <Button>Community</Button>
      </ButtonGroup>
    </div>
  );
}
