import { Auth } from "aws-amplify";

export async function getUser() {
  const authedUser = await Auth.currentAuthenticatedUser().catch(() =>
    console.log("Not signed in [currentAuthenticatedUser]")
  );

  const currUser = await Auth.currentUserInfo().catch(() =>
    console.log("Not signed in [currentUserInfo]")
  );

  return { authedUser, currUser };
}
