// Footer.tsx of [snippethub-web], at 210916

import {
  CardContent,
  CardHeader,
  IconButton,
  styled,
  useTheme,
} from "@mui/material";
import { GitHub, BorderStyle } from "@mui/icons-material";
import { WideDiv, VDiv } from "UI/Styled";

export function Footer() {
  const theme = useTheme();
  const TotalFooterWrap = styled("div")({
    width: "100%",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: "40px",
  });

  const FooterWrap = styled("div")({
    display: "flex",
    justifyContent: "space-around",
    borderBottomWidth: "10px",
    borderBottomStyle: "solid",
    borderImage: "var(--three-color-gradient) 1",
    padding: "8px 0px",
  });

  return (
    <TotalFooterWrap>
      <FooterWrap>
        <WideDiv>
          <CardHeader title="ICONS"></CardHeader>
          <CardContent>
            <VDiv>
              <IconButton aria-label="GitHub" size="large">
                <GitHub style={{ fontSize: "50px" }} />
              </IconButton>
              <IconButton aria-label="GitHub" size="large">
                <BorderStyle style={{ fontSize: "50px" }} />
              </IconButton>
            </VDiv>
          </CardContent>
        </WideDiv>
        <WideDiv>
          <CardHeader title="Support"></CardHeader>
          <CardContent>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
          </CardContent>
        </WideDiv>
        <WideDiv>
          <CardHeader title="Company"></CardHeader>
          <CardContent>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
          </CardContent>
        </WideDiv>
        <WideDiv>
          <CardHeader title="Terms & Policies"></CardHeader>
          <CardContent>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
            <div>Lorem ipsum</div>
          </CardContent>
        </WideDiv>
      </FooterWrap>
    </TotalFooterWrap>
  );
}
