// Logo.tsx of [snippethub-web], at 210926

import { styled } from "@mui/material";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import { Link } from "react-router-dom";

interface LogoProps {
  style?: React.CSSProperties;
}
export function Logo({ style }: LogoProps) {
  return (
    <StyledLink to="/">
      <LogoWrap style={style}>
        <DeveloperModeIcon />
        SnippetHub
      </LogoWrap>
    </StyledLink>
  );
}

const LogoWrap = styled("div")({
  fontSize: "large",
});

const StyledLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
  "& :visited": {
    color: "inherit",
  },
});
