import { Auth } from "aws-amplify";
export { signUp } from "./aws-service/signUp";
export { login } from "./aws-service/login";
export { getUser } from "./aws-service/getUser";

export async function getCurrUser() {
  return await Auth.currentUserInfo();
}

export async function logOut() {
  return await Auth.signOut();
}
