import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// DEV
import devopt from "dev/devopt";
// AWS
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { Provider } from "react-redux";
import { store } from "redux/store";
// Import the functions you need from the SDKs you need

// TODO: move creds to ENV
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// TODO: remove AWS
Amplify.configure(awsExports);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-EEUdvLjPtN4tMrHfiHIw6qv5nsHd_NY",
  authDomain: "snippethub-web.firebaseapp.com",
  projectId: "snippethub-web",
  storageBucket: "snippethub-web.appspot.com",
  messagingSenderId: "219285990089",
  appId: "1:219285990089:web:614a6067cb80dcb3cc7b18",
  measurementId: "G-6EMJW8QBH3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const devEnv = process.env.NODE_ENV === "development";
// comment for not mix with next line
(async () => {
  if (devEnv) {
    if (devopt.showCurPos) {
      const devtools = await import("dev/devtools");
      devtools.showCurPos();
    }
  }
})();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
