// IconFollowingCursor.tsx of [snippethub-web], at 210924
// modified from https://www.youtube.com/watch?v=WqgKe3dcXxg
import { styled } from "@mui/material";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";

const px = (size: number) => `${size}px`;

interface IconFollowingCursorProps {
  size: number;
}
const IconFollowingCursor = ({ size = 200 }: IconFollowingCursorProps) => {
  const faceSize = useMemo(() => px(size), [size]);
  const mouthHeight = useMemo(() => px(0.234 * size), [size]);
  const percent = useMemo(
    () => (percentage: number) => `${(percentage * size) / 100}px`,
    [size]
  );

  const Face = styled("div")({
    position: "relative",
    backgroundColor: "#ffcd00",
    width: faceSize,
    height: faceSize,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&::before": {
      content: '""',
      position: "absolute",
      top: percent(60),

      width: percent(50),
      height: mouthHeight,
      background: "#b57700",

      borderBottomLeftRadius: mouthHeight,
      borderBottomRightRadius: mouthHeight,
      transition: "all .25s",
    },

    "&:hover::before": {
      top: percent(70),
      height: percent(6),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  });

  const Eyes = styled("div")({
    position: "absolute",
    top: percent(20),
    display: "flex",
  });

  const EyeBall = styled("div")({
    position: "relative",
    width: percent(30),
    height: percent(30),
    background: "#fff",
    marginLeft: percent(5),
    marginRight: percent(5),
    borderRadius: "50%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const Iris = styled("div")({
    position: "relative",
    width: percent(15),
    height: percent(15),
    background: "#333",
    borderRadius: "50%",
  });

  const Eye = () => {
    const [pos, setPos] = useState<[number, number]>([10, 0]);
    const eyeBallRef = useRef<HTMLDivElement>(null);
    //30 = eyeball %size, 15 = iris %size
    const radius = useMemo(() => ((30 - 15) / 100 / 2) * size, []);
    const eyeBallCenter = useRef<[number, number]>([0, 0]);
    const getEyeBallCenter = useCallback((event?) => {
      if (!eyeBallRef.current) {
        eyeBallCenter.current = [0, 0];
        return;
      }
      eyeBallCenter.current = [
        eyeBallRef.current.getBoundingClientRect().left +
          eyeBallRef.current.clientWidth / 2,
        eyeBallRef.current.getBoundingClientRect().top +
          eyeBallRef.current.clientHeight / 2,
      ];
      return;
    }, []);
    const moveIris = useCallback(
      (event: MouseEvent) => {
        const center = eyeBallCenter.current;
        const x = event.clientX - center[0];
        const y = event.clientY - center[1];
        const dist = Math.sqrt(x * x + y * y);
        /* eyeball margin / size = 0.05 */
        const radial = (Math.atan(dist / (0.05 * size)) / Math.PI) * 2 * radius; // (-Inf,Inf)=>(0,1);
        setPos([(radial * x) / dist, (radial * y) / dist]);
      },
      [radius]
    );

    useEffect(() => {
      getEyeBallCenter();
      window.addEventListener("scroll", getEyeBallCenter);
      window.addEventListener("resize", getEyeBallCenter);
      window.addEventListener("mousemove", moveIris);
      return () => {
        window.removeEventListener("scroll", getEyeBallCenter);
        window.removeEventListener("resize", getEyeBallCenter);
        window.removeEventListener("mousemove", moveIris);
      };
    }, [getEyeBallCenter, moveIris]);

    return (
      <EyeBall ref={eyeBallRef}>
        <Iris style={{ left: `${pos[0]}px`, top: `${pos[1]}px` }} />
      </EyeBall>
    );
  };

  return (
    <Face>
      <Eyes>
        <Eye />
        <Eye />
      </Eyes>
    </Face>
  );
};

export { IconFollowingCursor };
