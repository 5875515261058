// MainHeader.tsx of [snippethub-web], at 210915

import { styled, useTheme } from "@mui/material";
import { Logo } from "component/Logo";
import { SearchField } from "./SearchField";
import { UserIconDropdown } from "./UserIconDropdown";

export function MainHeader() {
  const theme = useTheme();
  const MainHeaderWrap = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 2rem",
    borderTop: `1px solid ${theme.palette.divider}`,
  });
  return (
    <MainHeaderWrap>
      <Logo style={{ padding: "8px 2rem 0 0" }} />
      <SearchField />
      <UserIconDropdown style={{ marginLeft: "1rem" }} withChevron />
    </MainHeaderWrap>
  );
}
