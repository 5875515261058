// HomePage.tsx of [snippethub-web], at 210917

import { Header } from "../component/Header";
import { Footer } from "../component/Footer";
import { MainPopular } from "../component/home-page-components/MainPopular";
import { MainDiscover } from "../component/home-page-components/MainDiscover";
import { MainStat } from "../component/home-page-components/MainStat";
import { RecentSnippets } from "../component/home-page-components/RecentSnippets";
import { useTheme, styled } from "@mui/material";

export function HomePage() {
  /* ======== MUI ======== */
  const theme = useTheme();
  const CardsWrap = styled("div")({
    position: "relative",
    margin: "20px 0 0",
    display: "flex",
    maxWidth: "100%",
    padding: "10px",
    backgroundColor: theme.palette.background.default,
  });

  return (
    <>
      <Header />
      <BodyWrap>
        <CardsWrap>
          <MainPopular />
          <MainDiscover />
          <MainStat />
        </CardsWrap>
        <CardsWrap>
          <RecentSnippets />
        </CardsWrap>
      </BodyWrap>
      <Footer />
    </>
  );
}

const BodyWrap = styled("div")({
  flex: "1 1 auto",
  margin: "0 20px 40px",
});
