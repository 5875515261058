// BorderTopGradient.tsx of [snippethub-web], at 210917

import { styled } from "@mui/material";

export const BorderTopGradient = styled("div")({
  width: "100%",
  borderWidth: "10px 0 0",
  borderTopStyle: "solid",
  borderImage: "var(--three-color-gradient) 1",
});
