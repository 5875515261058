import { Auth } from "aws-amplify";

interface LoginParams {
  username: string;
  password: string;
}
export async function login({ username, password }: LoginParams) {
  try {
    const _res = await Auth.signIn({
      username,
      password,
    });
    console.log("_res : ", _res);
    return { exit: "success", data: _res };
  } catch (error) {
    console.error("Error in Login:");
    console.log(error);
    return { exit: "error", data: error };
  }
}
