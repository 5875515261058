/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_HXloRAA4T",
    "aws_user_pools_web_client_id": "45b26sqd26ifu9j7q00bt6qpo2",
    "oauth": {
        "domain": "snippethub.auth.eu-west-2.amazoncognito.com"
    },
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_cloud_logic_custom": [
        {
            "name": "snippethubGhOauthApi",
            "endpoint": "https://5dcc9r8tg7.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
