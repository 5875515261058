import React, { useEffect, useState } from "react";
import "./App.css";
import {
  createTheme,
  styled,
  ThemeProvider,
  StyledEngineProvider,
  GlobalStyles,
} from "@mui/material";

import { BrowserRouter } from "react-router-dom";
import { grey, orange, yellow } from "@mui/material/colors";
import { usePaletteDarkMode } from "util/hooks/usePaletteDarkMode";
import { getUser } from "util/aws-services";
import { Router } from "pages/Router";
/* ======== MUI ======== */

function App() {
  /* ======== MUI ======== */
  const { isPaletteDarkMode: isDarkMode } = usePaletteDarkMode();
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkMode ? "dark" : "light",
          ...(isDarkMode
            ? {
                primary: { main: yellow["A700"] },
                secondary: { main: orange["A700"] },
              }
            : {
                primary: { main: yellow["700"] },
                secondary: { main: orange["700"] },
                background: { default: grey["100"] },
              }),
        },
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              input: {
                "&:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 100px var(--primary-weak) inset",
                  WebkitTextFillColor: "var(--text-primary)",
                  // "-webkit-box-shadow": "0 0 0 100px var(--primary-weak) inset",
                  // "-webkit-text-fill-color": "var(--text-primary)",
                },
              },
            },
          },
        },
        typography: {
          button: {
            textTransform: "none",
          },
        },
      }),
    [isDarkMode]
  );

  /* ======== REACT ======== */
  const [isAuthed, setIsAuthed] = useState(false);
  const devEnv = process.env.NODE_ENV === "development";

  const AppWarp = styled("div")({
    backgroundColor: isDarkMode ? "#080808" : "#fff",
    minHeight: "100vh",
    maxWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // fontSize: "calc(10px + 2vmin)",
    color: theme.palette.text.primary,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            ":root": {
              "--primary-weak":
                theme.palette.primary[theme.palette.mode as "dark" | "light"],
              "--text-primary": theme.palette.text.primary,
            },
          }}
        />
        <AppWarp>
          <BrowserRouter>
            <Router isAuthed={isAuthed} />{" "}
          </BrowserRouter>
        </AppWarp>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
