// UserIconDropdown.tsx of [snippethub-web], at 210917

import {
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { AccountBox, ExpandMore, GitHub } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getUser, logOut } from "util/aws-services";
import getGhOAuthUrl from "util/gh-oauth/getGhOAuthUrl";

interface LogoProps {
  style?: React.CSSProperties;
  withChevron?: boolean;
}

export function UserIconDropdown({ style, withChevron }: LogoProps) {
  const theme = useTheme();
  const [username, setUsername] = useState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  async function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
    const userInfo = await getUser();
    setUsername(userInfo?.currUser?.username);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  const GitHubButton = ({
    onClick,
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => (
    <IconButton edge="end" style={{ padding: 0 }} onClick={onClick}>
      <GitHub
        style={{ fontSize: "1.5rem", color: theme.palette.primary.main }}
      />
    </IconButton>
  );

  function requireGhOAuth(event: React.MouseEvent) {
    event.preventDefault();
    const ghOAuthUrl = getGhOAuthUrl();
    window.location.href = ghOAuthUrl;
  }

  return (
    <div style={style}>
      <IconButton
        aria-label="account"
        aria-controls="account-menu"
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={handleClick}
        size="large"
      >
        <AccountBox style={{ fontSize: "40px" }} />
        {withChevron && <ExpandMore fontSize="small" />}
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        elevation={0}
        sx={{ "& > div": { width: "180px" } }}
      >
        {username && (
          <MenuItem
            disabled
            style={{ fontWeight: "bold", color: "revert", opacity: "1" }}
          >
            {username ?? "username"}
          </MenuItem>
        )}
        {username && <Divider />}
        <MenuItem component={Link} to="/login" style={{ paddingRight: "20px" }}>
          <ListItemText primary="Log In" />
          <GitHubButton onClick={requireGhOAuth} />
        </MenuItem>
        <MenuItem
          component={Link}
          to="/signUp"
          style={{ paddingRight: "20px" }}
        >
          <ListItemText primary="Sign Up" />
          <GitHubButton onClick={requireGhOAuth} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={logOut}>
          <ListItemText primary="Log Out" />
        </MenuItem>
      </Menu>
    </div>
  );
}
