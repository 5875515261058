// MainPopular.tsx of [snippethub-web], at 210926

import { List, ListItem, ListItemText } from "@mui/material";
import { WideDiv, WideDivHeader } from "UI/Styled";
import { Whatshot } from "@mui/icons-material";
import { useMemo } from "react";

export function MainPopular() {
  const listItems = useMemo<string[]>(
    () => [
      "lodash",
      "react",
      "chalk",
      "tslib",
      "axios",
      "commander",
      "express",
      "react-dom",
      "moment",
      "request",
    ],
    []
  );

  return (
    <WideDiv>
      <WideDivHeader
        icon={<Whatshot />}
        title="Popular Snippets"
        divider="var(--lime0)"
      ></WideDivHeader>
      <List style={{ margin: "10px" }}>
        {listItems.map((item, index) => (
          <ListItem divider dense button key={index}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </WideDiv>
  );
}
