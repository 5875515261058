import { Divider, styled, Typography } from "@mui/material";

export const WideDiv = styled("div")({
  flex: "1 1 25%",
  minHeight: "490px",
  margin: "0px 20px 40px",
  boxShadow: "none",
});

export const VDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "max-content",
});

const StyledDivider = styled(Divider)({
  borderWidth: "1px 0",
  borderStyle: "solid",
  margin: "10px",
  height: 0,
});
interface WideDivHeaderProps {
  title: string;
  icon?: JSX.Element;
  divider?: string;
}

/**
 * returns a JSX for includes an icon, text title and a divider.F
 *
 * @param {{title}} string : text title
 * @param {{icon?}} JSX.Element : icon used
 * @param {{divider?}} string : css variable / string of color / linear-gradient
 */
export function WideDivHeader({
  title,
  icon,
  divider,
}: WideDivHeaderProps): JSX.Element {
  let borderImage = "";
  if (!divider?.includes("gradient")) {
    borderImage = `linear-gradient(0deg,${divider},${divider})1`;
  } else {
    borderImage = divider;
  }
  return (
    <div>
      <div style={{ margin: "20px", display: "flex", alignItems: "flex-end" }}>
        <div style={{ margin: "0 10px" }}>{icon}</div>
        <Typography variant="h5">{title}</Typography>
      </div>
      {divider && (
        <StyledDivider
          style={{
            borderImage: borderImage,
          }}
          variant="middle"
        />
      )}
    </div>
  );
}
