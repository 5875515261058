// MainDiscover.tsx of [snippethub-web], at 210916

import { ButtonBase, styled, useTheme } from "@mui/material";
import { WideDiv, WideDivHeader } from "UI/Styled";
import { Search } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import {
  FlipToFront,
  FlipToBack,
  Code,
  Description,
  LineStyle,
  Check,
  DevicesOther,
  BeachAccess,
  PhoneIphone,
  BorderInner,
  Adb,
  Functions,
} from "@mui/icons-material";

export function MainDiscover() {
  /* ======== REACT ========*/
  const availableColors = useMemo(() => ["orange", "yellow", "lime"], []);

  const [btnColorIdx, setBtnColorIdx] = useState<0 | 1 | 2>(1);
  const genBtnHoverColor = useCallback(() => {
    setBtnColorIdx((prevIdx: 0 | 1 | 2) => {
      // const newIdx = (Math.ceil(Math.random() * 3) - 1) as 0 | 1 | 2;
      const newIdxAdd = Math.random() > 0.5 ? 1 : 2;
      return ((prevIdx + newIdxAdd) % 3) as 0 | 1 | 2;
    });
  }, []);
  const buttonsInfo = useMemo<{ [key: string]: JSX.Element }>(
    () => ({
      "Front-end": <FlipToFront sx={{ fontSize: 16 }} />,
      "Back-end": <FlipToBack sx={{ fontSize: 16 }} />,
      CLI: <Code sx={{ fontSize: 16 }} />,
      Documentation: <Description sx={{ fontSize: 16 }} />,
      CSS: <LineStyle sx={{ fontSize: 16 }} />,
      Testing: <Check sx={{ fontSize: 16 }} />,
      IoT: <DevicesOther sx={{ fontSize: 16 }} />,
      Coverage: <BeachAccess sx={{ fontSize: 16 }} />,
      Mobile: <PhoneIphone sx={{ fontSize: 16 }} />,
      Frameworks: <BorderInner sx={{ fontSize: 16 }} />,
      Robotics: <Adb sx={{ fontSize: 16 }} />,
      Math: <Functions sx={{ fontSize: 16 }} />,
    }),
    []
  );

  /* ======== MUI ========*/
  const theme = useTheme();
  const StyledButtonBase = styled(ButtonBase)({
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: `2px solid ${theme.palette.divider}`,
    backgroundColor: "transparent",
    transition: "background-color 0.2s ease-out",

    flex: "1",
    alignItems: "center",
    whiteSpace: "nowrap",
    "&>*": {
      padding: "25px 35px",
    },
    "&:hover": {
      backgroundColor: `var(--${availableColors[btnColorIdx]}0-a50)`,
      borderBottomColor: `var(--${availableColors[btnColorIdx]}1)`,
      transition: "background-color 0.1s linear",
    },
  });

  return (
    <WideDiv>
      <WideDivHeader
        icon={<Search />}
        title="Discover Snippets"
        divider="var(--yellow0)"
      ></WideDivHeader>
      <FlexWrap>
        {Object.entries(buttonsInfo).map(([key, icon]) => {
          return (
            <StyledButtonBase
              focusRipple
              onMouseLeave={genBtnHoverColor}
              key={key}
            >
              <div aria-label="flex-element">
                <div
                  style={{
                    display: "inline",
                    marginRight: "10px",
                  }}
                >
                  {icon}
                </div>
                {key}
              </div>
            </StyledButtonBase>
          );
        })}
      </FlexWrap>
    </WideDiv>
  );
}

const FlexWrap = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  margin: "10px",
  justifyContent: "center",
  gap: "10px",
});
