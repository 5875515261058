// MainStat.tsx of [snippethub-web], at 210916

import { Divider, styled, Typography } from "@mui/material";
import { WideDiv, WideDivHeader } from "UI/Styled";
import { BarChart } from "@mui/icons-material";

const SpaciousDiv = styled("div")({
  margin: "10px",
  "&>*": {
    margin: "10px",
  },
});

export function MainStat() {
  return (
    <WideDiv>
      <WideDivHeader
        icon={<BarChart />}
        title="By the numbers"
        divider="var(--orange0)"
      ></WideDivHeader>
      <SpaciousDiv>
        <Typography variant="h6">Snippets</Typography>
        <Typography variant="h4">0</Typography>
        <Divider />
        <Typography variant="h6">Downloads · Last Week</Typography>
        <Typography variant="h4">0</Typography>
        <Divider />
        <Typography variant="h6">Downloads · Last Month</Typography>
        <Typography variant="h4">0</Typography>
      </SpaciousDiv>
    </WideDiv>
  );
}
