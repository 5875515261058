// ValidatingField.tsx of [snippethub-web], at 210918

import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import emailValidator from "util/emailValidator";
import { FormInfo } from "./types";

// or just use https://react-hook-form.com/

interface FieldProps {
  infoStateHook: [FormInfo, (value: React.SetStateAction<FormInfo>) => void];
  validators?: [string, (value: string) => boolean][];
  autocomplete?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  lengthControl?: [number, number];
  size?: "small" | "medium";
  type?: "email" | "password";
  variant?: "filled" | "outlined" | "standard";
}
export function ValidatingField({
  infoStateHook: [info, setInfo],
  validators = [] as [string, (value: string) => boolean][],
  autocomplete,
  inputRef,
  lengthControl,
  size,
  type,
  variant,
}: FieldProps) {
  useEffect(() => {
    if (type === "email") {
      validators.push(["Please enter a valid email", (v) => emailValidator(v)]);
    }
    if (lengthControl !== undefined) {
      validators.push([
        `Please make sure length is between ${lengthControl[0]} and ${lengthControl[1]}`,
        (v) => lengthControl[0] <= v.length && v.length <= lengthControl[1],
      ]);
    }
  });
  const [errMsg, setErrMsg] = useState<string>("");
  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const v = event.target.value;
    let valid = true;
    let msgs: string[] = [];
    validators.forEach(([msg, validator]) => {
      const passed = validator(v);
      if (!passed) {
        valid = false;
        msgs.push(msg);
      }
    });
    setInfo({ value: v, isValid: valid });
    setErrMsg(msgs.join("; "));
    return;
  }
  return (
    <TextField
      autoComplete={autocomplete}
      error={info.isValid === false}
      helperText={!info.isValid && errMsg}
      inputRef={inputRef}
      onBlur={handleBlur}
      size={size}
      type={["password"].includes(type ?? "") ? type : undefined}
      onChange={(e) => {
        setInfo({ value: e.target.value, isValid: info.isValid });
      }}
      value={info.value}
      variant={variant}
    />
  );
}
