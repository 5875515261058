// getGhOAuthUrl.ts of [snippethub-web], at 210928

const devEnv = process.env.NODE_ENV === "development";
export default function getGhOAuthUrl() {
  const ghClientId = process.env.REACT_APP_GH_CLIENT_ID;
  const callbackParam = devEnv
    ? "GHOAuthSuccessCallbackTest"
    : "GHOAuthSuccessCallback";
  const callbackUrl = `https://www.snippethub.dev/redirect/github?${callbackParam}`;
  const ghOAuthUrl = `https://github.com/login/oauth/authorize?client_id=${ghClientId}&redirect_uri=${callbackUrl}`;
  return ghOAuthUrl;
}
