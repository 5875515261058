import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { HomePage } from "pages/HomePage";
import { SignUpPage } from "pages/SignUpPage";
import { LoginPage } from "pages/LoginPage";
import React, { useEffect } from "react";
import { RedirectPage } from "./RedirectPage";

interface RouterProps {
  isAuthed: boolean;
}
export function Router({ isAuthed }: RouterProps) {
  /*======== URL_QUERY ========*/
  const location = useLocation();
  const currentUrl = new URL(window.location.href);
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  function ScrollToTop() {
    const { pathname } = location;
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  /*======== test ========*/
  console.log("currentUrl : ", currentUrl);

  /*======== test end ========*/

  return (
    <React.Fragment>
      <ScrollToTop />
      <Switch>
        {isAuthed ? <Redirect exact from="/login" to="/" /> : ""}
        {isAuthed ? <Redirect exact from="/signUp" to="/" /> : ""}
        <Route exact path="/" children={<HomePage />} />
        <Route exact path="/login" children={<LoginPage />} />
        <Route path="/redirect" children={<RedirectPage />} />
        <Route exact path="/signUp" children={<SignUpPage />} />
      </Switch>
    </React.Fragment>
  );
}
