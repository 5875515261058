import { Auth } from "aws-amplify";

interface SignUpProp {
  username: string;
  password: string;
  email: string;
}
interface SignUpResponseData {
  code: string;
  message: string;
  name: string;
  user?: unknown;
}
export async function signUp({ username, password, email }: SignUpProp) {
  try {
    const _res = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
      },
    });
    const { user } = _res;
    return {
      exit: "success",
      data: {
        code: "SignUpSucceed",
        message: "User created successfully",
        name: "SignUpSucceed",
        user,
      } as SignUpResponseData,
    };
  } catch (error) {
    console.error("Error in SignUp:", error);
    return { exit: "error", data: error as SignUpResponseData };
  }
}
