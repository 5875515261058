// Header.tsx of [snippethub-web], at 210926

import { styled, useTheme } from "@mui/material";
import { MainHeader } from "./home-page-components/MainHeader";
import { SecondaryHeader } from "./home-page-components/SecondaryHeader";

export function Header() {
  const theme = useTheme();
  const HeaderWrap = styled("div")({
    position: "relative",
    top: "0",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
  });

  return (
    <HeaderWrap>
      <SecondaryHeader />
      <MainHeader />
    </HeaderWrap>
  );
}
